import { useEffect, useState } from 'react';

const queryStrings = {
  pc: 'screen and (min-width: 769px)',
  sp: 'screen and (max-width: 768px)',
};
const getCurrentMedia = (mediaList) => {
  let result = null;

  if (
    window.matchMedia(queryStrings[mediaList]).matches
    && mediaList === 'pc'
  ) {
    result = true;
  } else if (
    window.matchMedia(queryStrings[mediaList]).matches
    && mediaList === 'sp'
  ) {
    result = true;
  } else {
    result = false;
  }

  return result;
};

const useMediaQuery = (mediaList) => {
  const [device, setDevice] = useState('');

  useEffect(() => {
    let timeout;

    setDevice(getCurrentMedia(mediaList));

    const onResize = () => {
      // 500msに一度しかresizeイベントのcallbackを実行しないようにする
      if (timeout) return;

      const media = getCurrentMedia(mediaList);

      setDevice(media);
      timeout = setTimeout(() => {
        timeout = null;
      }, 300);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [mediaList]);

  return device;
};

export default useMediaQuery;
