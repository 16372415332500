const userAgent = () => {
  const useragent = window.navigator.userAgent;

  if (useragent.indexOf('Edge') !== -1) {
    return 'Edge';
  } if (
    useragent.indexOf('Trident') !== -1
    || useragent.indexOf('MSIE') !== -1
  ) {
    return 'ie';
  } if (
    useragent.indexOf('OPR') !== -1
    || useragent.indexOf('Opera') !== -1
  ) {
    return 'OPR';
  } if (useragent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  } if (useragent.indexOf('Firefox') !== -1) {
    return 'Firefox';
  } if (useragent.indexOf('Safari') !== -1) {
    return 'Safari';
  }
  return 'Unknown';
};
export default userAgent;
