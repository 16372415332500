/**
 * Type: コンポーネント
 * What: レイアウト
 */
import React, { useEffect, useState } from 'react';
import { scroller, animateScroll } from 'react-scroll';
import throttle from 'lodash/throttle';
import sal from 'sal.js';
import Header from './Header';
import Footer from './Footer';
import '../sass/index.scss';
import mq from '../util/useMediaQuery';
import userAgent from '../util/userAgent';

const TemplateWrapper = (props) => {
  const [scroll, setScroll] = useState(true);
  const [amount, setAmount] = useState(0);
  const [hbgOpen, setHbgOpen] = useState(false);
  const [hbgOpenHeight, setHbgOpenHeight] = useState(0);
  const [Ua, setUa] = useState('');
  const [lang, setLang] = useState('ja');
  // const [pageSlug, setPageSlug] = useState('');

  const throttleMethod = throttle(() => {
    // 間引き
    onScroll();
  }, 100);

  const onScroll = () => {
    const offset = window.pageYOffset
      || (document.documentElement && document.documentElement.scrollTop);

    setAmount(offset);

    if (offset >= 730) {
      setScroll(false);
    } else {
      setScroll(true);
    }
  };

  // ナビクリック
  const hbgClick = () => {
    const offsetY = window.pageYOffset;
    if (hbgOpen) {
      setHbgOpen(false);
      setTimeout(() => {
        window.scrollTo(0, hbgOpenHeight);
      }, 30);
    } else {
      setHbgOpenHeight(offsetY);
      setHbgOpen(true);
    }
  };

  useEffect(() => {
    // setPageSlug(props.page);

    /* eslint no-undef: 0 */
    window.addEventListener('wovnApiReady', (evt) => {
      setLang(WOVN.io.getCurrentLang().code);
    });

    // スクロールアクション
    sal({
      // threshold: 0.1,
      // once: false,
    });

    setUa(userAgent());

    document.addEventListener('scroll', throttleMethod);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.page, throttleMethod]);

  useEffect(() => {
    // 別ページからアンカーリンクで遷移してきた場合に発火
    // 遅延処理しないと読み込み遅いページだとずれる
    setTimeout(() => {
      ankerLinkScrollFromeOtherPage();
    }, 300);
  }, []);

  // ページ外アンカーリンク実行関数（同窓別ページアンカーリンクだとスクロール位置がずれるため）
  // （スクロールアニメーションがないページのみ有効）
  const ankerLinkScrollFromeOtherPage = () => {
    const hrefUrl = window.location.href;
    if (hrefUrl.indexOf("?id=") !== -1) {
      const id = hrefUrl.split("?id=")[1];

      scroller.scrollTo(id, {
        duration: 0,
        delay: 0,
        offset: 0,
        smooth: 'easeOutQuint',
      });

      // PC
      let $offset = -160;

      // SP
      if (window.matchMedia('screen and (max-width: 768px)').matches) {
        $offset = -110;
      }

      scroller.scrollTo(id, {
        duration: 1200,
        delay: 0,
        offset: $offset,
        smooth: 'easeOutQuint',
      });
    }
  };

  return (
    <>
      <div
        // className={`wrapper yukidaruma ${props.pageSlug ? `page-${props.pageSlug}` : ''}${hbgOpen ? ' slideWindow' : ''}${props.bg ? ` ${props.bg}` : ''}${Ua ? ` ua${Ua}${lang ? ` lang-${lang}` : ''}` : ''}`}
        className={`wrapper ${props.pageSlug ? `page-${props.pageSlug}` : ''}${hbgOpen ? ' slideWindow' : ''}${props.bg ? ` ${props.bg}` : ''}${Ua ? ` ua${Ua}${lang ? ` lang-${lang}` : ''}` : ''}`}
      >
        <Header
          hbgClick={hbgClick}
          hbgOpen={hbgOpen}
          setHbgOpen={setHbgOpen}
          hbgOpenheight={hbgOpenHeight}
          scroll={scroll}
          amount={amount}
          mq={mq}
          lang={lang}
          setLang={setLang}
        />
        <main>
          {props.children}
          <div role="button" tabIndex="0" aria-label="protection" className="protection" onClick={hbgClick} onKeyDown={props.hbgClick} />
        </main>
        <Footer pageSlug={props.pageSlug} amount={amount} mq={mq} />
        <div className="position-fix-button-group btn-wrap">
          <a href="/inquiry/group" className="btn-regular tac">
            <span>
              BOSS E・ZO FUKUOKA
              <br />
              団体予約フォーム
            </span>
            <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
              <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
              <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
            </svg>
          </a>
        </div>
      </div>
    </>
  );
};

export default TemplateWrapper;
