/**
 * Type: コンポーネント
 * What: ボタン
 */
import React from 'react';
import { Link } from 'gatsby';
import Arrow from '../images/btn/btn-def-arrow.svg';
import Image from '../util/Image';
import useMediaQuery from '../util/useMediaQuery';

const mq = useMediaQuery;

// const svgtext = props =>(
//   <>
//     <svg viewBox="0 0 200 100">
//       <text className="background" x="0" y="50%">縁取り</text>
//       <text className="foreground" x="0" y="50%">縁取り</text>
//     </svg>
//     <>
// )

export const ImgWrap = (props) => (<span>{props.children}</span>);
export const SvgText = (props) => (
  // const PC = mq('pc');
  <>
    {props.children}
  </>
);
export const SvgText2 = (props) => {
  const PC = mq('pc');
  return (
    <>
      <svg width={PC ? 250 : 100} height="80" viewBox={`0 0 ${PC ? 250 : 100} 80`}>
        <text className="background" x="50%" y="50%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
        <text className="medium" x="50%" y="50%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
        <text className="foreground" x="50%" y="50%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
      </svg>
    </>
  );
};

export const Text = (props) => (
  <>
    {props.children}
    <Image src="btn-l-arrow.svg" width="74" height="38" />
  </>
);

export const Title2 = (props) => {
  const PC = mq('pc');
  return (
    <>
      {
        PC ? (
          <svg width={props.width ? props.width : 400} height={props.shadow ? '55' : '80'} viewBox={`0 0 ${props.width ? props.width : 400} ${props.shadow ? '55' : '80'}`}>
            {props.width ? (
              <text className="shadow" x={props.shadow ? props.shadow : '51%'} y="59%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
            ) : (
              <text className="shadow" x="52%" y="59%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
            )}
            <text className="background" x="50%" y="50%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
            <text className="medium" x="50%" y="50%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
            <text className="foreground" x="50%" y="50%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
          </svg>
        ) : (
          <svg width={props.width ? props.width : 200} height={props.width ? 42 : 60} viewBox={`0 0 ${props.width ? props.width : 200} ${props.width ? 42 : 60}`}>
            {props.width ? (
              <text className="shadow" x={props.shadow ? props.shadow : '51%'} y="59%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
            ) : (
              <text className="shadow" x="52%" y="59%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
            )}
            <text className="background" x="50%" y="50%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
            <text className="medium" x="50%" y="50%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
            <text className="foreground" x="50%" y="50%" textAnchor="middle" dominantBaseline="central" letterSpacing="4">{props.children}</text>
          </svg>
        )
      }
    </>
  );
};

export const Title = (props) => (
  <span>
    {props.children}
  </span>
);

export const TitleZekkei = (props) => (
  <span className="ttl-zekkei">
    {props.children}
  </span>
);

export const Btn = (props) => (
  <>
    {
      props.target ? (
        <a href={props.href} className={`btn ${props.className}`} target="_blank" rel="noopener noreferrer">{props.children}</a>
      ) : (
        <Link to={props.href} className={`btn ${props.className ? props.className : ''}`}>{props.children}</Link>
      )
    }
  </>
);

export const Button = (props) => (
  <>
    {
      props.target ? (
        <a href={props.href} className={`btn-regular ${props.className}`} target="_blank" rel="noopener noreferrer">
          <span>{props.children}</span>
          {props.className === 'btn-buy' ? (
            <img src={Arrow} alt="" />
          ) : (
            <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
              <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
              <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
            </svg>
          )}
        </a>
      ) : (
        <Link to={props.href} className={`btn-regular ${props.className ? props.className : ''}`}>
          <span>{props.children}</span>
          {props.className === 'btn-buy' || 'btn-zekkei' ? (
            <img src={Arrow} alt="" />
          ) : (
            <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
              <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
              <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
            </svg>
          )}
        </Link>
      )
    }
  </>
);
