import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';

/*
 * filename で指定する場合は、gatsby-image で出力
 * src で指定する場合は画像で表示
 * SVGをgatsby-imageで表示しようとすると失敗するのでsrc で指定
 */
// width

const Image = (props) => (
  // const MaxWidth = props.width ? props.width : '1440';
  // console.log(MaxWidth);
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              extension
              publicURL
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) => {
        if (n.node.extension === 'svg' || props.src) return n.node.relativePath.includes(props.src); // svg はこけるので
        if (props.filename) return n.node.relativePath.includes(props.filename);
        return false;
      });

      if (!image) {
        return null;
      }

      // if (props.filename) {
      //   // const imageSizes = image.node.childImageSharp.sizes;
      //   const imageFluid = image.node.childImageSharp.fluid;
      //   return (
      //     <Img
      //       alt={props.alt ? props.alt : ''}
      //       fluid={imageFluid}
      //       className={props.className ? props.className : ''}
      //     />
      //   );
      // }
      return (
        <img
          style={image.node.imageStyle}
          src={image.node.publicURL}
          alt={props.alt ? props.alt : ''}
          className={props.className ? props.className : ''}
          width={props.width ? props.width : ''}
          height={props.height ? props.height : ''}
        />
      );
    }}
  />
);
export default Image;
